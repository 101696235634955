window.cookieconsent = initCookieConsent();

window.cookieconsent.run({
    current_lang: 'en',
    autoclear_cookies: true,
    page_scripts: true,

    delay: 250,
    revision: 0,

    gui_options: {
        consent_modal: {
            layout: 'box',
            position: 'bottom right',
            transition: 'slide',
            swap_buttons: false
        },
        settings_modal: {
            layout: 'box',
            transition: 'slide'
        }
    },

    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All", you consent to the use of ALL the cookies.',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Settings',
                    role: 'settings'
                }
            },
            settings_modal: {
                title: 'Privacy Overview',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Domain' },
                    { col3: 'Expiration' },
                    { col4: 'Description' }
                ],
                blocks: [{
                    title: 'Cookie usage',
                    description: 'This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website.'
                }, {
                    title: 'Strictly necessary cookies',
                    description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                    toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true
                    },
                    cookie_table: [{
                        col1: 'cc_cookie',
                        col2: 'mamyweb.pl',
                        col3: '6 months',
                        col4: 'This cookie is set to record the users preferences for the cookies.',
                    }, {
                        col1: 'lang',
                        col2: 'mamyweb.pl',
                        col3: '6 months',
                        col4: 'This cookies is set to record lang preferency.',
                    }]
                }, {
                    title: 'More information',
                    description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" id="cc-settings-hide-popup" href="/#contact-us">contact us</a>.',
                }]
            }
        },
        'pl': {
            consent_modal: {
                title: 'Używamy ciasteczek!',
                description: 'W celu poprawy jakości przeglądania strony używamy ciasteczek. Klikając "Zaakceptuj wszystkie" zgadzasz się na używanie WSZYSTKICH ciasteczek.',
                primary_btn: {
                    text: 'Zaakceptuj wszystkie',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Ustawienia',
                    role: 'settings'
                }
            },
            settings_modal: {
                title: 'Przegląd prywatności',
                save_settings_btn: 'Zapisz ustawienia',
                accept_all_btn: 'Zaakceptuj wszystkie',
                reject_all_btn: 'Odrzuć wszystkie',
                close_btn_label: 'Zamknij',
                cookie_table_headers: [
                    { col1: 'Nazwa' },
                    { col2: 'Domena' },
                    { col3: 'Wygaśnięcie po' },
                    { col4: 'Opis' }
                ],
                blocks: [{
                    title: 'Użycie ciasteczek',
                    description: 'W celu poprawy jakości przeglądania strony używamy ciasteczek. Ciasteczka w kategori "Niezbędne" są zapisywane automatycznie w Twojej przeglądarce, ponieważ są niezbędne do prawidłowego działania strony.'
                }, {
                    title: 'Niezbędne ciasteczka',
                    description: 'Te ciasteczka są niezbędne do prawidłowego działania strony. Bez nich strona nie będzie działać poprawnie.',
                    toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true
                    },
                    cookie_table: [{
                        col1: 'cc_cookie',
                        col2: 'mamyweb.pl',
                        col3: '6 miesiącach',
                        col4: 'To ciasteczko zapamiętuje wybór ustawień ciasteczek.',
                    }, {
                        col1: 'lang',
                        col2: 'mamyweb.pl',
                        col3: '6 miesiącach',
                        col4: 'To ciasteczko zapisuje wybrany język.',
                    }]
                }, {
                    title: 'Więcej informacji',
                    description: 'Jeżeli masz więcej pytań to <a class="cc-link" id="cc-settings-hide-popup" href="/#contact-us">skontaktuj się z nami</a>.',
                }]
            }
        },
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All", you consent to the use of ALL the cookies.',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Settings',
                    role: 'settings'
                }
            },
            settings_modal: {
                title: 'Privacy Overview',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Domain' },
                    { col3: 'Expiration' },
                    { col4: 'Description' }
                ],
                blocks: [{
                    title: 'Cookie usage 📢',
                    description: 'This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website.'
                }, {
                    title: 'Strictly necessary cookies',
                    description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                    toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true
                    },
                    cookie_table: [{
                        col1: 'cc_cookie',
                        col2: 'mamyweb.pl',
                        col3: '6 months',
                        col4: 'This cookie is set to record the users preferences for the cookies.',
                    }, {
                        col1: 'lang',
                        col2: 'mamyweb.pl',
                        col3: '6 months',
                        col4: 'This cookies is set to record lang preferency.',
                    }]
                }, {
                    title: 'More information',
                    description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" id="cc-settings-hide-popup" href="/#contact-us">contact us</a>.',
                }]
            }
        }
    }
});

document.getElementById("cc-settings-hide-popup").addEventListener("click", () => {
    window.cookieconsent.hideSettings();
});

if (window.currentLang) {
    window.cookieconsent.updateLanguage(window.currentLang);
}