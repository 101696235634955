let navbar = document.getElementById('navbar');
let navbarCollapse = document.getElementById('navbarCollapse');
let navbarBtn = document.getElementById("navbar-button");
let navbarLinks = document.querySelectorAll('#navbar .nav-link');

const scrolledNavbar = function(event) {
    let navbar_height;
    if (window.innerWidth <= 991) {
        navbar_height = 48;
    } else {
        navbar_height = 96;
    }
    if (window.scrollY >= navbar_height) {
        navbar.classList.add('scrolled');
    } else {
        navbar.classList.remove('scrolled');
    }
}

window.addEventListener('scroll', scrolledNavbar, false);
window.addEventListener('resize', scrolledNavbar, false);
document.addEventListener('DOMContentLoaded', scrolledNavbar, false);

//mobile menu opening before scroll fix

navbarBtn.addEventListener("click", () => {
    if (navbarBtn.classList.contains('collapsed')) {
        navbar.classList.remove('expanded');
    } else {
        navbar.classList.add('expanded');
    }

});

//close navbar on menu item clikc

navbarLinks.forEach(el => {
    el.addEventListener('click', () => {
        if (window.innerWidth <= 991) {
            navbarBtn.click();
        }
    });
});