//cookies

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

document.addEventListener("DOMContentLoaded", () => {
    setCookie('lang', window.currentLang.toUpperCase(), 180);
});

document.querySelectorAll('.lang-selector .dropdown-item').forEach((el) => {
    el.addEventListener('click', () => {
        if (el.dataset.lang) {
            setCookie('lang', el.dataset.lang, 180);
        }
    });
});

//logo button scrolltop

let scrollToTopBtns = document.querySelectorAll('.scroll-to-top');
if (scrollToTopBtns.length) {
    scrollToTopBtns.forEach(el => {
        el.addEventListener('click', (event) => {
            event.preventDefault();
            window.focus();
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, 100);
            history.pushState({}, '', '/');
        });
    });
}

// leaves animation

let leaves = document.querySelectorAll('.leaf.animation');
let lastScrollTop;
let firstScroll = true;
let scrollTimeout = undefined;
let scrollTimeoutDelay = 150;



window.addEventListener('resize', () => {
    firstScroll = (!firstScroll) ? true : false;
});

window.addEventListener("scroll", function() {
    // print "false" if direction is down and "true" if up
    if (firstScroll == true) {
        firstScroll = false;
        this.oldScroll = this.scrollY;
        return;
    }
    let leafRotationDir = (window.innerWidth > 991) ? this.oldScroll > this.scrollY : this.oldScroll < this.scrollY;
    if (leafRotationDir) {
        leaves.forEach(el => {
            el.classList.add("scrolling-up");
            el.classList.remove("scrolling-down");
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                leaves.forEach(el => {
                    el.classList.remove("scrolling-up");
                });
            }, scrollTimeoutDelay);
        });
    } else {
        leaves.forEach(el => {
            el.classList.add("scrolling-down");
            el.classList.remove("scrolling-up");
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                leaves.forEach(el => {
                    el.classList.remove("scrolling-down");
                });
            }, scrollTimeoutDelay);
        });
    }

    this.oldScroll = this.scrollY;
    window.lastScrollTime = new Date().getTime();
});

function is_scrolling() {
    return window.lastScrollTime && new Date().getTime() < window.lastScrollTime + 500
}

// paralax

// window.addEventListener("scroll", function() {
//     //let distance = window.scrollY;
//     let paralaxImages = document.querySelectorAll(".paralax-wrapper img");
//     paralaxImages.forEach(el => {
//         let parentPosition = el.parentNode.getBoundingClientRect();
//         let translate = (((parentPosition.y - window.innerHeight) / el.height)) * 100;
//         el.style.transform = `translateY(${translate}%)`;
//     });
// });

// ES6 import
import simpleParallax from 'simple-parallax-js';

var images = document.querySelectorAll('.paralax-wrapper img');
new simpleParallax(images, {
    scale: '1.4',
});